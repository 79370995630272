import React, { Component }  from 'react';
import { ThumbComponent } from './ThumbComponent';
import { connect } from 'react-redux'
import { userReacted, userSaidWithoutRemovingLastMessageAndPost, userSaid } from './action.js'
import axios from 'axios'

const styles ={ 
    float: 'right',
    color: 'red',
    color: '#6c757d',
    // fontStyle: 'italic',
    fontSize: '0.85em',
    paddingLeft : 5,
}
class ThumbsComponent extends Component{
    constructor(props) {
        super(props);
        this.state = { liked: false, unliked: false, deactivated: false, tempSession: ''};
        this.toggleLike = this.toggleLike.bind(this);
        this.toggleNotLike = this.toggleNotLike.bind(this);
    }
    componentWillMount(){
        // this.props.userSaid('Hello');
    }
    toggleLike() {
        this.state.deactivated = true;
        let newV = !this.state.liked;
        let isReaction = false;
        this.tempSession = this.props.sessionId;
        if(this.props.hasBtn) isReaction = true;
        console.log(this.props.sentQuery)
        this.setState({ liked: newV, unliked:false });
        if(!this.props.hasBtn) this.props.userReacted('Hello',{query : 'thumb up',reaction: 1, sentQuery : this.props.sentQuery});
        if(this.props.hasBtn) this.postReaction(1,this.tempSession);
    }
    postReaction(reaction, tempSession){
        console.log(this.props.sentQuery)
        axios.post("/server.php", {reaction : reaction, name: this.props.intentName['name'], sentQuery : this.props.sentQuery , isReaction:true, sessionId: tempSession})
        .then(response => {
           console.log(response)
        })
        .catch(error => {
            
        });
    } 
    toggleNotLike() {
        this.state.deactivated = true;
        let newV = !this.state.unliked;
        let isReaction = false;
        this.tempSession = this.props.sessionId;
        if(this.props.hasBtn) isReaction = true;
        this.setState({ unliked : newV, liked : false});
        if(!this.props.hasBtn) this.props.userReacted('Hello',{query : 'thumb down',reaction: 0, sentQuery : this.props.sentQuery});
        if(this.props.hasBtn) this.postReaction(0,this.tempSession);
    } 
    render(){
        return (<span style={styles}>
            {this.props.texte}
            <ThumbComponent onClickLikeBtn={this.toggleLike} deact={this.state.deactivated} clicked={this.state.liked} direction="up" />
            <ThumbComponent onClickLikeBtn={this.toggleNotLike} deact={this.state.deactivated} clicked={this.state.unliked} direction="down" />
        </span>);
    }
}
export default connect(null, { userReacted })(ThumbsComponent);